import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import styles from "./404.module.css";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className={styles.root}>
      <div>
        <h1>NOT FOUND!</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
